<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" text="提交中"></loading>
      <el-form ref="form" :model="task"  label-width="100px">
          <el-form-item label="任务分类">
            <el-radio-group v-model="task.categoryId" @change="handleChange">
              <el-radio :label="taskCategoryEnum.RI_CHANG">日常</el-radio>
              <el-radio :label="taskCategoryEnum.DU_XUE">督学</el-radio>
              <el-radio :label="taskCategoryEnum.MATCH">挑战赛</el-radio>
              <el-radio :label="taskCategoryEnum.MRYL">每日一练</el-radio>
            </el-radio-group>
      </el-form-item>
        <el-form-item label="任务周期" :required="true"  v-show="checkIsShow('divExpire')">
          <el-date-picker
              :disabled="isDateTimeDisabled"
              v-model="dateTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <course-form-item-multiple ref="courseFormItemMultiple" v-show="checkIsShow('divCourse')"></course-form-item-multiple>
        <el-form-item label="任务名称"  :required="true">
          <el-input type="text"  v-model="task.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="奖励积分" :required="true">
          <el-input type="number" v-model="task.integral" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="任务类型" :required="true">
          <el-select v-model="task.type" placeholder="请选择" style="width:100%">
            <el-option v-for="type in types" :key="type.id" :label="type.name" :value="type.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务目标" :required="true" v-show="checkIsShow('divTaskTargetLesson')">
          <div class="layui-input-block" style="height: 26px; min-height: 26px; color: #40AFFE; cursor: pointer" @click="handleClick('lesson')">选择课时 >></div>
          <div  class="layui-input-block question-list">
            <p v-for="(lesson,index) in this.lessons">
              <span style='color: #333'>{{(index+1) + ". ["+lesson.id+"]"}} </span>
              <span style='color: #666'> {{lesson.name}}</span>
              <span style='margin-left: 10px; color:#FF5722;cursor: pointer;' @click="removeLesson(lesson.id);">[删除]</span>
            </p>
          </div>
        </el-form-item>
        <el-form-item label="任务目标" :required="true" v-show="checkIsShow('divTaskTargetQuestion')">
          <div class="layui-input-block" style="height: 26px; min-height: 26px; color: #40AFFE; cursor: pointer" @click="handleClick('question')">选择题目 >></div>
          <div  class="layui-input-block question-list">
            <p v-for="(question,index) in this.questions">
              <span style='color: #333'>{{(index+1) + ". ["+question.id+"]"}} </span>
              <span style='color: #666'> {{question.stem}}</span>
              <span style='margin-left: 10px; color:#FF5722;cursor: pointer;' @click="removeQuestion(question.id);">[删除]</span>
            </p>
          </div>
        </el-form-item>
        <el-form-item label="任务量" :required="true" v-show="checkIsShow('divVolume')">
          <el-input type="number" v-model="task.volume" :placeholder="getVolumeTip()"></el-input>
        </el-form-item>
        <el-form-item label="排序" :required="true" v-show="this.id != 0">
          <el-input type="number" v-model="task.sort"    placeholder="请输入" ></el-input>
        </el-form-item>
        <el-form-item style="display: none">
          <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  QuestionTypeEnum,
  TipEnum,
  UrlEnum,
  TaskCategoryEnum,
  getLocalProjectId,
  TaskTypeEnum,
  CourseCategoryEnum,
  request
} from "../../../../public/js/common-vue";
import {SESSON_KEY_INTEGRAL_SELCOURSE} from "../../../../public/js/sesson-key-vue";
import CourseFormItemMultiple from "@/components/CourseFormItemMultiple";
import Loading from "@/components/Loading";

export default {
  name: "TaskSave",
  data() {
    return {
      loading: false,
      dateTime: [new Date(), new Date()],
      types:[],
      lessons:[],
      questions:[],
      id:0,
      sort:'',
      taskCategoryEnum:TaskCategoryEnum,
      isDateTimeDisabled:false,
      duxueCategoryIds:CourseCategoryEnum.MSK + "," + CourseCategoryEnum.SSK + "," + CourseCategoryEnum.TS + "," + CourseCategoryEnum.ZBK + "," + CourseCategoryEnum.WSK + "," + CourseCategoryEnum.XXB+","+CourseCategoryEnum.FS_MFK+","+CourseCategoryEnum.FS_WSK+","+CourseCategoryEnum.FS_ZBK+","+CourseCategoryEnum.FS_MSK+","+CourseCategoryEnum.FS_TS,
      machCategoryIds:CourseCategoryEnum.FK_MATCH+","+CourseCategoryEnum.FS_MATCH,
      task:{
        name:'',
        type:'',
        volume:'',
        categoryId:TaskCategoryEnum.DU_XUE,
        courseIds:[],
        integral:'',
        sort:''
      }
    }
  },
  components: {Loading, CourseFormItemMultiple},
  mounted() {
    this.getTypes();
    if(request('id') != null && request('id') != ''){
      this.id=request('id');
      this.initData(null);
    }
    else{
      this.$refs.courseFormItemMultiple.initData(null,this.duxueCategoryIds);
    }
  },
  methods:{
    handleChange(val){
      if(val == this.taskCategoryEnum.DU_XUE){
        this.$refs.courseFormItemMultiple.initData(this.task.courses,this.duxueCategoryIds);
      }
      else if(val == this.taskCategoryEnum.MATCH){
        this.$refs.courseFormItemMultiple.initData(this.task.courses,this.machCategoryIds);
      }
    },
    handleClick(type){
      let selectedQuestions = [];
     if(type == 'question'){
       let typeIds = ''
        if(this.task.type == TaskTypeEnum.ZUO_YE_KG || this.task.type == TaskTypeEnum.KE_GUAN_TI) {
          typeIds = QuestionTypeEnum.DAN_XUAN + "," + QuestionTypeEnum.DUO_XUAN + "," + QuestionTypeEnum.BU_DING_XIANG;
        }
        else if(this.task.type == TaskTypeEnum.TIAN_KONG){
          typeIds = QuestionTypeEnum.TIAN_KONG;
        }
        else if(this.task.type == TaskTypeEnum.PAN_DUAN){
          typeIds = QuestionTypeEnum.PAN_DUAN;
        }
        else{
          typeIds = QuestionTypeEnum.CAILIAO + "," + QuestionTypeEnum.JIAN_DA + "," + QuestionTypeEnum.LUN_SHU + "," + QuestionTypeEnum.FA_TIAO + "," + QuestionTypeEnum.AN_LI + "," + QuestionTypeEnum.FEN_XI + "," + QuestionTypeEnum.FENXI_LUNSHU;
        }
        selectedQuestions = this.questions;
        this.$router.push({
          name:'SelectQuestion',
          params:{
            typeIds: typeIds,
            selectedQuestions:selectedQuestions
          }
        })
      }
     else if(type == 'lesson'){
       this.$router.push({
         name:'SelectLesson',
         params:{
           selectedLessons:this.lessons
         }
       })
     }
    },
    removeQuestion(id){
      for(let i = this.questions.length-1; i>=0; i--){
        if(this.questions[i].id == id){
          this.questions.splice(i,1);
        }
      }
    },
    removeLesson(id){
      for(let i = this.lessons.length-1; i>=0; i--){
        if(this.lessons[i].id == id){
          this.lessons.splice(i,1);
        }
      }
    },
    checkIsShow(divId) {
      const taskType = this.task.type;
      const categoryId = this.task.categoryId;
      if(divId == 'divCourse'){
        if(categoryId == TaskCategoryEnum.DU_XUE || categoryId == TaskCategoryEnum.MATCH){
          return true;
        }
        return false;
      }
      if(divId == 'divExpire'){
        if(categoryId == TaskCategoryEnum.DU_XUE || categoryId == TaskCategoryEnum.MATCH){
          return true;
        }
        return false;
      }
      if(divId == 'divTaskTargetLesson'){
        if(taskType == TaskTypeEnum.TING_KE){
          if(categoryId == TaskCategoryEnum.DU_XUE || categoryId == TaskCategoryEnum.MATCH){
            return true;
          }
        }
        return false;
      }
      if(divId == 'divTaskTargetQuestion'){
        if(categoryId == TaskCategoryEnum.DU_XUE || categoryId == TaskCategoryEnum.MATCH || categoryId == TaskCategoryEnum.MRYL){
          if(
              taskType == TaskTypeEnum.KE_GUAN_TI ||
              taskType == TaskTypeEnum.ZUO_YE_KG ||
              taskType == TaskTypeEnum.ZHU_GUAN_TI ||
              taskType == TaskTypeEnum.ZUO_YE_ZG ||
              taskType == TaskTypeEnum.PAN_DUAN ||
              taskType == TaskTypeEnum.TIAN_KONG
          ) {
            return true;
          }
          else {
            return false;
          }
        }
        else{
          return false;
        }
      }
      if(divId == 'divVolume'){
        if(taskType == TaskTypeEnum.TING_KE
            || taskType == TaskTypeEnum.KE_GUAN_TI
            || taskType == TaskTypeEnum.ZUO_YE_KG
            || taskType == TaskTypeEnum.ZHU_GUAN_TI
            || taskType == TaskTypeEnum.ZUO_YE_ZG
            || taskType == TaskTypeEnum.COMMENT
            || taskType == TaskTypeEnum.PIN_TUAN
            || taskType == TaskTypeEnum.YAO_QING_HAO_YOU
            || taskType == TaskTypeEnum.PAN_DUAN
            || taskType == TaskTypeEnum.TIAN_KONG
        ){
          return true;
        }
        return false;
      }
    },
    getVolumeTip(){
      if(this.task.type == TaskTypeEnum.TING_KE){
        return "请输入听课时长（分钟）"
      }
      else if(this.task.type == TaskTypeEnum.KE_GUAN_TI || this.task.type == TaskTypeEnum.ZHU_GUAN_TI || this.task.type == TaskTypeEnum.ZUO_YE_KG || this.task.type == TaskTypeEnum.ZUO_YE_ZG){
        return "请输入做题数量"
      }
      else if(this.task.type == TaskTypeEnum.COMMENT){
        return "请输入评价次数"
      }
      else if(this.task.type == TaskTypeEnum.PIN_TUAN){
        return "请输入拼团次数"
      }
      else if(this.task.type == TaskTypeEnum.YAO_QING_HAO_YOU){
        return "请输入邀请人数"
      }
      return "";
    },
    //获取任务类型
    getTypes() {
      this.$http({
        method: "get",
        url: UrlEnum.INTEGRAL_TYPES,
      }).then((res) => {
        this.types = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    //获取lessonIds
    getLessonIds() {
      const lessonIds = [];
      for(var i = 0;i < this.lessons.length; i++){
        const lessonId = this.lessons[i].id;
        lessonIds[i] = lessonId;
      }
      return lessonIds;
    },
    getQuestionIds() {
      let questions = this.questions;
      const questionIds = [];
      for(let i = 0; i < questions.length; i++){
        questionIds[i] = questions[i].id;
      }
      return questionIds;
    },
    submit:function (){
      if(this.checkIsShow('divExpire') && this.dateTime == null){
        this.showMsgError("请选择任务周期");
        return;
      }
      if(this.checkIsShow('divCourse') && this.$refs.courseFormItemMultiple.getCheckedIds().length == 0){
        this.showMsgError("请选择督学课程");
        return;
      }
      if(this.task.name == ""){
        this.showMsgError("请输入任务名称");
        return;
      }
      if(this.task.integral == ""){
        this.showMsgError("请输入奖励积分");
        return;
      }
      if(this.task.type == ""){
        this.showMsgError("请选择任务类型");
        return;
      }
      if(this.checkIsShow('divTaskTargetLesson') && this.lessons.length == 0){
        this.showMsgError("请选择课时");
        return;
      }
      if(this.checkIsShow('divTaskTargetQuestion') && this.questions.length == 0){
        this.showMsgError("请选择客观题");
        return;
      }
      if(this.checkIsShow('divTaskTargetZhuGuan') && this.questions.length == 0){
        this.showMsgError("请选择主观题")
        return;
      }
      if(this.checkIsShow("divVolume") && this.task.volume == ''){
        this.showMsgError("请填写任务量")
        return;
      }
      if(this.id != 0 && this.task.sort == ''){
        this.showMsgError("请填写排序")
        return;
      }
      this.task.projectId = getLocalProjectId();
      this.task.courseIds = this.$refs.courseFormItemMultiple.getCourseIds();
      this.task.lessonIds = this.getLessonIds();
      this.task.questionIds = this.getQuestionIds();
      this.task.startTime = this.moment(this.dateTime[0]).format("YYYY-MM-DD HH:mm:ss");
      this.task.endTime = this.moment(this.dateTime[1]).format("YYYY-MM-DD HH:mm:ss");
      if(this.task.volume == ''){
        this.task.volume = 1
      }
      //保存课程会话
      this.$refs.courseFormItemMultiple.setSessionCourse(this.$refs.courseFormItemMultiple.getCheckedCourses());
      this.loading = true;
      let url = UrlEnum.INTEGRAL_TASKS;
      var method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }
      this.$http({
        method: method,
        url: url,
        data: this.task,
      })
      .then((res) => {
        this.loading=false;
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        setTimeout(function () {
          window.parent.postMessage({
            msg: '',
            key: 'refresh'
          }, '*')
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    initData(){
      this.loading = true;
      this.$http({
        method: 'GET',
        url: UrlEnum.INTEGRAL_TASKS + "/" + request('id'),
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.task = res.data;
        if(this.task.categoryId == TaskCategoryEnum.DU_XUE){
          this.$refs.courseFormItemMultiple.initData(this.task.courses,this.duxueCategoryIds);
        }
        else if(this.task.categoryId == TaskCategoryEnum.MATCH){
          this.$refs.courseFormItemMultiple.initData(this.task.courses,this.machCategoryIds);
        }
        this.dateTime=[this.task.startTime,this.task.endTime];
        if(new Date(this.task.startTime) < new Date()){
          this.isDateTimeDisabled = true;
        }
        if(this.task.categoryId == TaskCategoryEnum.DU_XUE || this.task.categoryId == TaskCategoryEnum.MATCH || this.task.categoryId == TaskCategoryEnum.MRYL) {
          if (this.task.type == TaskTypeEnum.ZHU_GUAN_TI ||
              this.task.type == TaskTypeEnum.ZUO_YE_ZG ||
              this.task.type == TaskTypeEnum.KE_GUAN_TI ||
              this.task.type == TaskTypeEnum.ZUO_YE_KG ||
              this.task.type == TaskTypeEnum.PAN_DUAN ||
              this.task.type == TaskTypeEnum.TIAN_KONG
          ) {
            this.questions = this.task.questions;
          }
        }
        if((this.task.categoryId == TaskCategoryEnum.DU_XUE || this.task.categoryId == TaskCategoryEnum.MATCH) && (this.task.type == TaskTypeEnum.TING_KE)){
          this.lessons = this.task.lessons;
        }
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
  }
}
</script>

<style scoped>
.question-list{
  border: 1px solid #eee;
  padding: 10px;
  margin-top: 10px;
  min-height: 36px;
}
.question-list p{
  padding:0px;
  line-height: 20px;
}
</style>
